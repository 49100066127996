import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Card,
  CardContent,
  CardActionArea,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  Alert,
} from '@mui/material';
import {
  Map as MapIcon,
  Agriculture as AgricultureIcon,
  Assignment as AssignmentIcon,
  NavigateNext as NavigateNextIcon,
  Thermostat as ThermostatIcon,
  Opacity as OpacityIcon,
  Air as AirIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [mappedAreasStats, setMappedAreasStats] = useState({ totalArea: 0, count: 0 });
  const [sprayingStats, setSprayingStats] = useState({ activeArea: 0, activeCount: 0 });
  const [tasksStats, setTasksStats] = useState({ total: 0, pending: 0 });
  const [tasks, setTasks] = useState([]);
  const [recentServices, setRecentServices] = useState([]);

  useEffect(() => {
    // Carregar estatísticas das áreas mapeadas
    const storedAreas = localStorage.getItem('mappedAreas');
    if (storedAreas) {
      try {
        const areas = JSON.parse(storedAreas);
        const totalArea = areas.reduce((sum, area) => sum + parseFloat(area.area || 0), 0);
        setMappedAreasStats({
          totalArea: totalArea.toFixed(2),
          count: areas.length
        });
      } catch (error) {
        console.error('Erro ao carregar áreas:', error);
      }
    }

    // Carregar estatísticas das pulverizações e serviços recentes
    const storedOperations = localStorage.getItem('agroDroneOperations');
    if (storedOperations) {
      try {
        const operations = JSON.parse(storedOperations);
        const activeOps = operations.filter(op => op.status === 'Em Andamento');
        const activeArea = activeOps.reduce((sum, op) => sum + parseFloat(op.area || 0), 0);
        setSprayingStats({
          activeArea: activeArea.toFixed(2),
          activeCount: activeOps.length
        });

        // Pegar os 3 serviços mais recentes
        const sortedOperations = [...operations].sort((a, b) => 
          new Date(b.date || b.createdAt) - new Date(a.date || a.createdAt)
        ).slice(0, 3);
        setRecentServices(sortedOperations);
      } catch (error) {
        console.error('Erro ao carregar operações:', error);
      }
    }

    // Carregar tarefas
    const storedTasks = localStorage.getItem('agroDroneTasks');
    if (storedTasks) {
      try {
        const allTasks = JSON.parse(storedTasks);
        const dashboardTasks = allTasks.filter(task => task.showOnDashboard);
        const pendingTasks = dashboardTasks.filter(task => !task.completed);
        setTasks(dashboardTasks);
        setTasksStats({
          total: dashboardTasks.length,
          pending: pendingTasks.length
        });
      } catch (error) {
        console.error('Erro ao carregar tarefas:', error);
      }
    }
  }, []);

  const getPriorityColor = (priority) => {
    switch (priority.toLowerCase()) {
      case 'alta':
      case 'high':
        return '#d32f2f';
      case 'média':
      case 'medium':
        return '#ed6c02';
      default:
        return '#2e7d32';
    }
  };

  const getSprayingCondition = (temp, humidity, windSpeed) => {
    if (windSpeed > 15) return { status: 'bad', message: 'Vento muito forte para pulverização' };
    if (humidity < 50) return { status: 'warning', message: 'Umidade baixa, risco de deriva' };
    if (temp > 30) return { status: 'warning', message: 'Temperatura alta, monitore a deriva' };
    return { status: 'good', message: 'Condições favoráveis para pulverização' };
  };

  const getConditionColor = (status) => {
    switch (status) {
      case 'good': return '#2e7d32';
      case 'warning': return '#ed6c02';
      case 'bad': return '#d32f2f';
      default: return '#637381';
    }
  };

  const dashboardItems = [
    {
      title: 'Áreas Mapeadas',
      value: `${mappedAreasStats.totalArea} ha`,
      subtext: `${mappedAreasStats.count} área${mappedAreasStats.count !== 1 ? 's' : ''}`,
      icon: <MapIcon />,
      color: '#1976d2',
      bgColor: '#e3f2fd',
      path: '/maps'
    },
    {
      title: 'Pulverizações',
      value: `${sprayingStats.activeArea} ha`,
      subtext: `${sprayingStats.activeCount} em andamento`,
      icon: <AgricultureIcon />,
      color: '#2e7d32',
      bgColor: '#e8f5e9',
      path: '/spraying'
    },
    {
      title: 'Tarefas',
      value: tasksStats.total.toString(),
      subtext: `${tasksStats.pending} pendente${tasksStats.pending !== 1 ? 's' : ''}`,
      icon: <AssignmentIcon />,
      color: '#ed6c02',
      bgColor: '#fff3e0',
      path: '/tasks'
    }
  ];

  const MobileCard = ({ item }) => (
    <Card 
      sx={{ 
        mb: 1,
        bgcolor: item.bgColor,
        borderRadius: 2,
        height: '100%'
      }}
    >
      <CardActionArea onClick={() => navigate(item.path)}>
        <CardContent sx={{ 
          py: 1,
          px: 1.5,
          '&:last-child': { pb: 1 }
        }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: 0.5
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box sx={{ 
                color: item.color,
                display: 'flex',
                alignItems: 'center'
              }}>
                {item.icon}
              </Box>
              <Typography variant="body2" color="text.secondary">
                {item.title}
              </Typography>
            </Box>
            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
              {item.value}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );

  const DesktopCard = ({ item }) => (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        height: 180,
        bgcolor: item.bgColor,
        cursor: 'pointer',
        transition: '0.3s',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: 4,
        },
      }}
      onClick={() => navigate(item.path)}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Box sx={{ 
          p: 1.5,
          borderRadius: 2,
          bgcolor: 'white',
          color: item.color,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          {item.icon}
        </Box>
        <IconButton 
          size="small" 
          sx={{ color: item.color }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(item.path);
          }}
        >
          <NavigateNextIcon />
        </IconButton>
      </Box>
      <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
        {item.value}
      </Typography>
      <Typography color="text.secondary" sx={{ mb: 1 }}>
        {item.title}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mt: 'auto' }}>
        {item.subtext}
      </Typography>
    </Paper>
  );

  const ServiceCard = ({ service }) => (
    <Card 
      sx={{ 
        mb: 1,
        borderRadius: 2,
        bgcolor: '#f1f8e9',  
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        transition: 'all 0.3s ease',
        border: '1px solid #dcedc8',  
        '&:hover': {
          boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
          transform: 'translateY(-2px)',
          bgcolor: '#f4faf0',  
        }
      }}
    >
      <CardActionArea onClick={() => navigate('/spraying')}>
        <CardContent sx={{ py: 1.5, px: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                {new Date(service.date || service.createdAt).toLocaleDateString()}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500, color: '#33691e' }}>  
                {service.description || 'Pulverização'}
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="h6" sx={{ color: '#2e7d32', fontWeight: 'bold' }}>  
                {service.area} ha
              </Typography>
              <Chip 
                size="small"
                label={service.status}
                sx={{ 
                  bgcolor: service.status === 'Em Andamento' ? '#c8e6c9' : '#f5f5f5',
                  color: service.status === 'Em Andamento' ? '#1b5e20' : 'text.secondary',
                  fontWeight: 500,
                  border: '1px solid',
                  borderColor: service.status === 'Em Andamento' ? '#a5d6a7' : '#e0e0e0',
                }}
              />
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
      <Grid container spacing={isMobile ? 1 : 3}>
        {dashboardItems.slice(0, 2).map((item, index) => (
          <Grid item xs={6} sm={6} md={6} key={index}>
            {isMobile ? (
              <MobileCard item={item} />
            ) : (
              <DesktopCard item={item} />
            )}
          </Grid>
        ))}
        {dashboardItems.slice(2).map((item, index) => (
          <Grid item xs={12} sm={12} md={12} key={index}>
            {isMobile ? (
              <MobileCard item={item} />
            ) : (
              <DesktopCard item={item} />
            )}
          </Grid>
        ))}

        {/* Lista de Tarefas */}
        {tasks.length > 0 && (
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                bgcolor: '#fff',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>
                  Tarefas em Destaque
                </Typography>
                <Button
                  size="small"
                  endIcon={<NavigateNextIcon />}
                  onClick={() => navigate('/tasks')}
                  sx={{ textTransform: 'none' }}
                >
                  Ver todas
                </Button>
              </Box>
              <List>
                {tasks.map((task, index) => (
                  <React.Fragment key={task.id}>
                    {index > 0 && <Divider />}
                    <ListItem
                      sx={{
                        px: 0,
                        py: 1.5,
                      }}
                    >
                      <ListItemText
                        primary={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                textDecoration: task.completed ? 'line-through' : 'none',
                                color: task.completed ? 'text.secondary' : 'text.primary',
                              }}
                            >
                              {task.title}
                            </Typography>
                            <Chip
                              size="small"
                              label={task.priority}
                              sx={{
                                bgcolor: `${getPriorityColor(task.priority)}15`,
                                color: getPriorityColor(task.priority),
                                fontWeight: 500,
                                fontSize: '0.75rem',
                              }}
                            />
                          </Box>
                        }
                        secondary={
                          <Typography variant="caption" color="text.secondary">
                            {new Date(task.dueDate).toLocaleDateString()}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>
        )}

        {/* Últimos Serviços */}
        <Grid item xs={12}>
          <Box sx={{ mt: 2, mb: 1 }}>
            <Typography variant="h6" sx={{ fontSize: '1rem', color: 'text.secondary', mb: 1 }}>
              Últimos Serviços
            </Typography>
            {recentServices.map((service, index) => (
              <ServiceCard key={index} service={service} />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
