import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Container,
  Grid,
  Paper,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  Drawer,
  AppBar as MuiAppBar,
} from '@mui/material';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  Dashboard as DashboardIcon,
  Map as MapIcon,
  Agriculture as AgricultureIcon,
  Assignment as AssignmentIcon,
  Settings as SettingsIcon,
  Inventory as InventoryIcon,
  Science as ScienceIcon,
  Assessment as AssessmentIcon,
} from '@mui/icons-material';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Sidebar = styled('nav', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  position: 'fixed',
  height: '100vh',
  backgroundColor: '#f8f9fa',
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  transition: theme.transitions.create(['transform', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [theme.breakpoints.down('sm')]: {
    transform: open ? 'translateX(0)' : 'translateX(-100%)',
    zIndex: theme.zIndex.drawer,
  },
  '& .MuiListItemButton-root': {
    margin: '4px 8px',
    borderRadius: '8px',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#e8f5e9',
      '& .MuiListItemIcon-root': {
        color: '#2e7d32',
      },
      '& .MuiListItemText-primary': {
        color: '#2e7d32',
      },
    },
    '&.Mui-selected': {
      backgroundColor: '#e8f5e9',
      '&:hover': {
        backgroundColor: '#c8e6c9',
      },
      '& .MuiListItemIcon-root': {
        color: '#2e7d32',
      },
      '& .MuiListItemText-primary': {
        color: '#2e7d32',
        fontWeight: 600,
      },
    },
  },
  '& .MuiListItemIcon-root': {
    color: '#637381',
    minWidth: '40px',
    transition: 'color 0.2s ease',
  },
  '& .MuiListItemText-primary': {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: '#637381',
    transition: 'color 0.2s ease',
  },
  '& .MuiToolbar-root': {
    minHeight: '64px',
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  '& .MuiDivider-root': {
    margin: '8px 0',
  },
}));

const Layout = ({ children }) => {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/' },
    { text: 'Mapas', icon: <MapIcon />, path: '/maps' },
    { text: 'Pulverização', icon: <AgricultureIcon />, path: '/spraying' },
    { text: 'Produtos', icon: <InventoryIcon />, path: '/products' },
    { text: 'Calda', icon: <ScienceIcon />, path: '/mixture' },
    { text: 'Tarefas', icon: <AssignmentIcon />, path: '/tasks' },
    { text: 'Relatórios', icon: <AssessmentIcon />, path: '/reports' },
    { text: 'Configurações', icon: <SettingsIcon />, path: '/settings' },
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open} sx={{ 
        backgroundColor: '#fff',
        boxShadow: 'none',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      }}>
        <Toolbar
          sx={{
            pr: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: '64px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              edge="start"
              aria-label="menu"
              onClick={toggleDrawer}
              sx={{ 
                mr: 2,
                color: '#637381',
                ...(open && {
                  display: 'none',
                }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              sx={{ 
                flexGrow: 1,
                color: '#212B36',
                fontWeight: 600,
              }}
            >
              Drone T40
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>

      <Sidebar
        variant={isMobile ? 'temporary' : 'permanent'}
        open={open}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          {open && (
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          )}
        </Toolbar>
        <Divider />
        <List component="nav">
          {menuItems.map((item) => (
            <ListItemButton
              key={item.text}
              component={RouterLink}
              to={item.path}
              selected={location.pathname === item.path}
              onClick={isMobile ? toggleDrawer : undefined}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          ))}
        </List>
      </Sidebar>

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          ml: { sm: open ? `${drawerWidth}px` : 0 },
          width: { sm: `calc(100% - ${open ? drawerWidth : 0}px)` },
          transition: (theme) =>
            theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
