import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import Layout from './components/Layout';
import Dashboard from './components/Dashboard';
import Maps from './components/Maps';
import Spraying from './components/Spraying';
import Tasks from './components/Tasks';
import Reports from './components/Reports';
import Settings from './components/Settings';
import Products from './components/Products';
import Mixture from './components/Mixture';
import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Navigate to="/" replace />} />
            <Route path="/maps" element={<Maps />} />
            <Route path="/spraying" element={<Spraying />} />
            <Route path="/products" element={<Products />} />
            <Route path="/mixture" element={<Mixture />} />
            <Route path="/tasks" element={<Tasks />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
