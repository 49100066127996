import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CalculateIcon from '@mui/icons-material/Calculate';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

const Mixture = () => {
  const [mixtures, setMixtures] = useState(() => {
    const savedMixtures = localStorage.getItem('agroDroneMixtures');
    return savedMixtures ? JSON.parse(savedMixtures) : [];
  });

  const [mappedAreas, setMappedAreas] = useState(() => {
    const savedMappedAreas = localStorage.getItem('mappedAreas');
    return savedMappedAreas ? JSON.parse(savedMappedAreas) : [];
  });

  const [products, setProducts] = useState(() => {
    const savedProducts = localStorage.getItem('agroDroneProducts');
    return savedProducts ? JSON.parse(savedProducts) : [];
  });

  useEffect(() => {
    const savedProducts = localStorage.getItem('agroDroneProducts');
    if (savedProducts) {
      setProducts(JSON.parse(savedProducts));
    }
  }, []);

  const [currentMixture, setCurrentMixture] = useState({
    name: '',
    areas: [], // Array de áreas selecionadas
    area: '0.00', // Área total
    totalVolume: '0.00',
    applicationRate: 10,
    products: []
  });

  const [selectedProduct, setSelectedProduct] = useState({
    product: '',
    dosage: '',
  });

  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    mixtureId: null,
    mixtureName: ''
  });

  useEffect(() => {
    localStorage.setItem('agroDroneMixtures', JSON.stringify(mixtures));
  }, [mixtures]);

  useEffect(() => {
    localStorage.setItem('mappedAreas', JSON.stringify(mappedAreas));
  }, [mappedAreas]);

  const handleAddProduct = () => {
    if (!selectedProduct || !selectedProduct.product) return;

    const product = products.find(p => p.id === selectedProduct.product);
    if (!product) return;

    const dosage = parseFloat(selectedProduct.dosage);
    if (isNaN(dosage)) return;

    const area = parseFloat(currentMixture.area) || 0;
    const newProduct = {
      product: product.id,
      name: product.name || 'Sem nome',
      dosage: dosage,
      totalAmount: calculateProductAmount(dosage, area)
    };
    
    setCurrentMixture(prev => ({
      ...prev,
      products: [...(prev.products || []), newProduct]
    }));

    setSelectedProduct({
      product: '',
      dosage: '',
    });
  };

  const handleRemoveProduct = (index) => {
    setCurrentMixture(prev => ({
      ...prev,
      products: prev.products.filter((_, i) => i !== index)
    }));
  };

  const calculateProductAmount = (dosage, area) => {
    if (!dosage || !area || isNaN(dosage) || isNaN(area)) return 0;
    return parseFloat((dosage * area).toFixed(2));
  };

  const calculateTotalVolume = () => {
    if (!currentMixture.area || !currentMixture.applicationRate) return '0.00';
    const area = parseFloat(currentMixture.area);
    const rate = parseFloat(currentMixture.applicationRate);
    if (isNaN(area) || isNaN(rate)) return '0.00';
    return (area * rate).toFixed(2);
  };

  const handleAreaChange = (event) => {
    const selectedAreaIds = event.target.value;
    const selectedAreas = mappedAreas.filter(area => selectedAreaIds.includes(area.id));
    
    const totalArea = selectedAreas.reduce((sum, area) => sum + (parseFloat(area.area) || 0), 0);
    const areaNames = selectedAreas.map(area => area.name).join(', ');
    const formattedArea = totalArea > 0 ? totalArea.toFixed(2) : '0.00';
    
    setCurrentMixture(prev => {
      const newRate = parseFloat(prev.applicationRate) || 0;
      return {
        ...prev,
        areas: selectedAreas,
        name: areaNames || '',
        area: formattedArea,
        totalVolume: calculateTotalVolume()
      };
    });

    // Update total amounts for existing products
    setCurrentMixture(prev => ({
      ...prev,
      products: prev.products.map(product => ({
        ...product,
        totalAmount: calculateProductAmount(product.dosage, formattedArea)
      }))
    }));
  };

  const handleApplicationRateChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    const area = parseFloat(currentMixture.area) || 0;
    setCurrentMixture(prev => ({
      ...prev,
      applicationRate: value,
      totalVolume: calculateTotalVolume()
    }));
  };

  const handleSaveMixture = () => {
    if (!currentMixture.name || !currentMixture.name.trim()) {
      alert('Por favor, insira um nome para a mistura');
      return;
    }

    if (!Array.isArray(currentMixture.products) || currentMixture.products.length === 0) {
      alert('Por favor, adicione pelo menos um produto à mistura');
      return;
    }

    const newMixture = {
      ...currentMixture,
      id: currentMixture.id || Date.now(),
      area: parseFloat(currentMixture.area || 0).toFixed(2),
      totalVolume: calculateTotalVolume(),
      applicationRate: parseFloat(currentMixture.applicationRate || 0).toFixed(2),
      products: currentMixture.products.map(p => ({
        ...p,
        dosage: parseFloat(p.dosage || 0).toFixed(2),
        totalAmount: calculateProductAmount(p.dosage, currentMixture.area)
      }))
    };

    setMixtures(prev => {
      const existingIndex = prev.findIndex(m => m.id === newMixture.id);
      if (existingIndex >= 0) {
        const updated = [...prev];
        updated[existingIndex] = newMixture;
        return updated;
      }
      return [...prev, newMixture];
    });

    setCurrentMixture({
      name: '',
      areas: [],
      area: '0.00',
      totalVolume: '0.00',
      applicationRate: 10,
      products: []
    });
  };

  const handleDeleteConfirm = () => {
    if (deleteDialog.mixtureId) {
      setMixtures(prev => prev.filter(mixture => mixture.id !== deleteDialog.mixtureId));
      setDeleteDialog({ open: false, mixtureId: null, mixtureName: '' });
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialog({ open: false, mixtureId: null, mixtureName: '' });
  };

  const handleDeleteClick = (mixture) => {
    setDeleteDialog({
      open: true,
      mixtureId: mixture.id,
      mixtureName: mixture.name
    });
  };

  const handleRemoveArea = (areaToRemove) => {
    setCurrentMixture(prev => {
      const updatedAreas = prev.areas.filter(area => area.id !== areaToRemove.id);
      const totalArea = updatedAreas.reduce((sum, area) => sum + (parseFloat(area.area) || 0), 0);
      const formattedArea = totalArea > 0 ? totalArea.toFixed(2) : '0.00';
      return {
        ...prev,
        areas: updatedAreas,
        area: formattedArea,
        totalVolume: calculateTotalVolume()
      };
    });
  };

  return (
    <Container>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h6" gutterBottom>
          Cálculo de Calda
        </Typography>

        <Grid container spacing={2}>
          {/* Mixture Calculator Form */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Áreas Mapeadas</InputLabel>
                  <Select
                    multiple
                    value={currentMixture.areas.map(a => a.id)}
                    onChange={handleAreaChange}
                    label="Áreas Mapeadas"
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {currentMixture.areas.map((area) => (
                          <Chip key={area.id} label={area.name} />
                        ))}
                      </Box>
                    )}
                  >
                    {mappedAreas.map((area) => (
                      <MenuItem key={area.id} value={area.id}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <Typography>{area.name}</Typography>
                          <Typography color="text.secondary">{area.area} ha</Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nome da Calda"
                  value={currentMixture.name}
                  onChange={(e) => setCurrentMixture(prev => ({ ...prev, name: e.target.value }))}
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ mb: 1 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Área Total: {currentMixture.area} ha
                  </Typography>
                  <Paper variant="outlined" sx={{ p: 1, bgcolor: 'background.default' }}>
                    {currentMixture.areas.length === 0 ? (
                      <Typography variant="body2" color="text.secondary">
                        Nenhuma área selecionada
                      </Typography>
                    ) : (
                      <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
                        {currentMixture.areas.map((area, index) => (
                          <Chip
                            key={index}
                            label={`${area.name} (${area.area} ha)`}
                            onDelete={() => handleRemoveArea(area)}
                            size="small"
                          />
                        ))}
                      </Stack>
                    )}
                  </Paper>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Volume Total"
                      value={currentMixture.totalVolume}
                      InputProps={{
                        readOnly: true,
                        endAdornment: <InputAdornment position="end">L</InputAdornment>
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Dosagem"
                      type="number"
                      value={currentMixture.applicationRate}
                      onChange={handleApplicationRateChange}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">L/ha</InputAdornment>
                      }}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ mb: 1 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Produtos
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel>Produto</InputLabel>
                        <Select
                          value={selectedProduct.product || ''}
                          onChange={(e) => setSelectedProduct(prev => ({ ...prev, product: e.target.value }))}
                          label="Produto"
                        >
                          {products.map((product) => (
                            <MenuItem key={product.id} value={product.id}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box
                                  component="img"
                                  src={product.image}
                                  alt={product.name}
                                  sx={{ width: 40, height: 40, objectFit: 'cover', borderRadius: 1 }}
                                />
                                <Box>
                                  <Typography variant="body2">{product.name}</Typography>
                                  <Typography variant="caption" color="text.secondary">
                                    {product.manufacturer} - {product.dosage}
                                  </Typography>
                                </Box>
                              </Box>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <TextField
                          fullWidth
                          label="Dosagem"
                          type="number"
                          value={selectedProduct.dosage}
                          onChange={(e) => setSelectedProduct(prev => ({ ...prev, dosage: e.target.value }))}
                          size="small"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">L/ha</InputAdornment>
                          }}
                        />
                        <Button
                          variant="contained"
                          onClick={handleAddProduct}
                          disabled={!selectedProduct.product || !selectedProduct.dosage}
                          startIcon={<AddIcon />}
                        >
                          Adicionar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                {/* Lista de Produtos Adicionados */}
                {currentMixture.products.length > 0 && (
                  <TableContainer component={Paper} variant="outlined" sx={{ mt: 2 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Produto</TableCell>
                          <TableCell>Dosagem</TableCell>
                          <TableCell align="right">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentMixture.products.map((product, index) => {
                          const productDetails = products.find(p => p.id === product.product);
                          if (!productDetails) return null;
                          return (
                            <TableRow key={index}>
                              <TableCell>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    component="img"
                                    src={productDetails.image}
                                    alt={productDetails.name}
                                    sx={{ width: 32, height: 32, objectFit: 'cover', borderRadius: 0.5 }}
                                  />
                                  <Box>
                                    <Typography variant="body2">{productDetails.name}</Typography>
                                    <Typography variant="caption" color="text.secondary">
                                      {productDetails.manufacturer}
                                    </Typography>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell>{product.dosage} L/ha</TableCell>
                              <TableCell align="right">
                                <IconButton
                                  size="small"
                                  onClick={() => handleRemoveProduct(index)}
                                  sx={{ padding: 0.5 }}
                                >
                                  <DeleteIcon sx={{ fontSize: '1rem' }} color="error" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveMixture}
                  disabled={!currentMixture.name || !currentMixture.products.length}
                >
                  Salvar Calda
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Saved Mixtures */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom sx={{ pl: 1 }}>
                Caldas Salvas
              </Typography>

              {mixtures.length === 0 ? (
                <Alert severity="info" sx={{ fontSize: '0.875rem' }}>
                  Nenhuma calda salva. Crie uma nova calda usando o formulário ao lado.
                </Alert>
              ) : (
                <TableContainer>
                  <Table size="small" sx={{ '& th, & td': { pl: 1, pr: 1 } }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontSize: '0.75rem', fontWeight: 'bold', width: '25%' }}>Nome</TableCell>
                        <TableCell sx={{ fontSize: '0.75rem', fontWeight: 'bold', width: '15%' }}>Área</TableCell>
                        <TableCell sx={{ fontSize: '0.75rem', fontWeight: 'bold', width: '20%' }}>Volume</TableCell>
                        <TableCell sx={{ fontSize: '0.75rem', fontWeight: 'bold', width: '30%' }}>Produtos</TableCell>
                        <TableCell sx={{ fontSize: '0.75rem', fontWeight: 'bold', width: '10%', textAlign: 'center' }}>Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mixtures.map((mixture) => (
                        <TableRow key={mixture.id} hover>
                          <TableCell sx={{ verticalAlign: 'top' }}>
                            <Typography sx={{ fontSize: '0.75rem', fontWeight: 'medium' }}>
                              {mixture.name}
                            </Typography>
                            <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem', display: 'block' }}>
                              {new Date(mixture.date).toLocaleDateString()}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ verticalAlign: 'top' }}>
                            <Typography sx={{ fontSize: '0.75rem', whiteSpace: 'nowrap' }}>
                              {parseFloat(mixture.area).toFixed(2)} ha
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ verticalAlign: 'top' }}>
                            <Typography sx={{ fontSize: '0.75rem', whiteSpace: 'nowrap' }}>
                              {parseFloat(mixture.totalVolume).toFixed(2)} L
                            </Typography>
                            <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem', display: 'block' }}>
                              ({parseFloat(mixture.applicationRate).toFixed(2)} L/ha)
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ verticalAlign: 'top' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25 }}>
                              {mixture.products.map((product, idx) => (
                                <Typography key={idx} sx={{ fontSize: '0.75rem' }}>
                                  {product.name}
                                </Typography>
                              ))}
                            </Box>
                          </TableCell>
                          <TableCell sx={{ verticalAlign: 'top', textAlign: 'center' }}>
                            <IconButton
                              size="small"
                              onClick={() => handleDeleteClick(mixture)}
                              sx={{ padding: 0.5 }}
                            >
                              <DeleteIcon sx={{ fontSize: '1rem' }} color="error" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={deleteDialog.open}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ pb: 1 }}>
          Confirmar Exclusão
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Tem certeza que deseja excluir a calda "{deleteDialog.mixtureName}"?
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Esta ação não poderá ser desfeita.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={handleDeleteCancel} color="inherit">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained" autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Mixture;
