import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Grid,
  InputAdornment,
  Card,
  CardContent,
  MenuItem,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  Warning as WarningIcon,
  Map as MapIcon,
  ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';

// Função para converter hectares em alqueires
const hectareToAlqueire = (hectares) => {
  const alqueires = hectares / 2.42;
  return alqueires.toFixed(2);
};

// Função para converter alqueires em hectares
const alqueireToHectare = (alqueires) => {
  return (alqueires * 2.42).toFixed(2);
};

const Maps = () => {
  const [openNewArea, setOpenNewArea] = useState(false);
  const [editingArea, setEditingArea] = useState(null);
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
  const [areaToDuplicate, setAreaToDuplicate] = useState(null);
  const [newArea, setNewArea] = useState({
    farmName: '',
    name: '',
    description: '',
    area: '',
    areaAlqueires: '',
    type: '',
    image: null,
  });
  
  const [maps, setMaps] = useState(() => {
    const savedMaps = localStorage.getItem('mappedAreas');
    console.log('Carregando áreas do localStorage:', savedMaps);
    return savedMaps ? JSON.parse(savedMaps) : [];
  });

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedArea, setSelectedArea] = useState(null);

  useEffect(() => {
    // Salvar no localStorage sempre que maps mudar
    console.log('Salvando áreas no localStorage:', maps);
    localStorage.setItem('mappedAreas', JSON.stringify(maps));
  }, [maps]);

  const culturas = [
    'Soja',
    'Milho',
    'Algodão',
    'Café',
    'Cana-de-açúcar',
    'Feijão',
    'Trigo',
    'Outros'
  ];

  const handleOpenNewArea = () => {
    setEditingArea(null);
    setNewArea({
      farmName: '',
      name: '',
      description: '',
      area: '',
      areaAlqueires: '',
      type: '',
      image: null,
    });
    setOpenNewArea(true);
  };

  const handleEditArea = (area) => {
    setEditingArea(area);
    setNewArea({
      farmName: area.farmName || '',
      name: area.name,
      description: area.description,
      area: area.area,
      areaAlqueires: hectareToAlqueire(area.area),
      type: area.type,
      image: area.image,
    });
    setOpenNewArea(true);
  };

  const handleDuplicateArea = (area) => {
    setAreaToDuplicate(area);
    setOpenDuplicateDialog(true);
  };

  const handleConfirmDuplicate = () => {
    if (areaToDuplicate) {
      const duplicatedArea = {
        ...areaToDuplicate,
        name: `${areaToDuplicate.name} (Cópia)`,
        id: Date.now().toString(),
      };
      
      const updatedMaps = [...maps, duplicatedArea];
      setMaps(updatedMaps);
      localStorage.setItem('mappedAreas', JSON.stringify(updatedMaps));
      
      setOpenDuplicateDialog(false);
      setAreaToDuplicate(null);
    }
  };

  const handleCloseNewArea = () => {
    setOpenNewArea(false);
    setEditingArea(null);
    setNewArea({
      farmName: '',
      name: '',
      description: '',
      area: '',
      areaAlqueires: '',
      type: '',
      image: null,
    });
  };

  const handleCreateArea = () => {
    if (!newArea.name || !newArea.area) {
      alert('Por favor, preencha o nome e a área');
      return;
    }

    const areaValue = parseFloat(newArea.area);
    if (isNaN(areaValue) || areaValue <= 0) {
      alert('Por favor, insira uma área válida');
      return;
    }

    const newAreaData = {
      id: editingArea ? editingArea.id : Date.now().toString(),
      name: newArea.name.trim(),
      farmName: (newArea.farmName || '').trim(),
      description: (newArea.description || '').trim(),
      area: areaValue.toFixed(2),
      type: newArea.type || 'Outros',
      image: newArea.image || 'https://via.placeholder.com/800x400',
      status: 'Ativo',
      lastUpdate: new Date().toISOString().split('T')[0],
      coordinates: '-23.5505, -46.6333',
      progress: 0
    };

    console.log('Criando nova área:', newAreaData);

    let updatedMaps;
    if (editingArea) {
      updatedMaps = maps.map(map => 
        map.id === editingArea.id ? newAreaData : map
      );
    } else {
      updatedMaps = [...maps, newAreaData];
    }

    console.log('Atualizando lista de áreas:', updatedMaps);
    setMaps(updatedMaps);
    handleCloseNewArea();
  };

  const handleDeleteArea = (id) => {
    const updatedMaps = maps.filter(map => map.id !== id);
    console.log('Removendo área, nova lista:', updatedMaps);
    setMaps(updatedMaps);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = () => {
    if (selectedArea) {
      handleDeleteArea(selectedArea.id);
      handleCloseDeleteDialog();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'areaAlqueires') {
      setNewArea(prev => ({
        ...prev,
        [name]: value,
        area: alqueireToHectare(value)
      }));
    } else if (name === 'area') {
      setNewArea(prev => ({
        ...prev,
        [name]: value,
        areaAlqueires: hectareToAlqueire(value)
      }));
    } else {
      setNewArea(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const totalArea = maps.reduce((acc, map) => acc + parseFloat(map.area), 0);

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5" component="h1" sx={{ fontSize: '1.2rem' }}>
          Áreas Mapeadas
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            Área Total: {totalArea.toFixed(2)} ha ({hectareToAlqueire(totalArea)} alq)
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpenNewArea}
            sx={{ fontSize: '0.875rem' }}
          >
            Nova Área
          </Button>
        </Box>
      </Box>

      <Grid container spacing={2}>
        {maps.map((map) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={map.id}>
            <Card 
              sx={{ 
                height: '100%',
                borderRadius: 2,
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                }
              }}
            >
              <Box sx={{ position: 'relative' }}>
                <CardContent sx={{ p: 1.5 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                    <Box>
                      <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
                        {map.farmName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {map.name}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
                        <Typography variant="body2" color="text.secondary">
                          {map.area} ha • {map.type}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      gap: 1.5
                    }}>
                      <IconButton
                        size="small"
                        onClick={() => handleEditArea(map)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleDuplicateArea(map)}
                        color="primary"
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog para criar/editar área */}
      <Dialog 
        open={openNewArea}
        onClose={handleCloseNewArea}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            {editingArea ? 'Editar Área' : 'Nova Área'}
            <IconButton onClick={handleCloseNewArea} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nome da Área"
                  name="name"
                  value={newArea.name}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nome da Fazenda"
                  name="farmName"
                  value={newArea.farmName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Área (ha)"
                  name="area"
                  type="number"
                  value={newArea.area}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    endAdornment: <InputAdornment position="end">ha</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Área (alq)"
                  name="areaAlqueires"
                  type="number"
                  value={newArea.areaAlqueires}
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">alq</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  label="Tipo de Cultura"
                  name="type"
                  value={newArea.type}
                  onChange={handleInputChange}
                >
                  {culturas.map((cultura) => (
                    <MenuItem key={cultura} value={cultura}>
                      {cultura}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Descrição"
                  name="description"
                  value={newArea.description}
                  onChange={handleInputChange}
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', px: 3, pb: 2 }}>
          <Box>
            {editingArea && (
              <Button
                onClick={() => {
                  setSelectedArea(editingArea);
                  setOpenDeleteDialog(true);
                }}
                startIcon={<DeleteIcon />}
                color="error"
              >
                Excluir
              </Button>
            )}
          </Box>
          <Box>
            <Button onClick={handleCloseNewArea} sx={{ mr: 1 }}>
              Cancelar
            </Button>
            <Button 
              onClick={handleCreateArea}
              variant="contained"
              disabled={!newArea.name || !newArea.area}
            >
              {editingArea ? 'Atualizar' : 'Criar'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Diálogo de confirmação para duplicar área */}
      <Dialog
        open={openDuplicateDialog}
        onClose={() => setOpenDuplicateDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Duplicar Área</DialogTitle>
        <DialogContent>
          <Typography>
            Deseja duplicar a área "{areaToDuplicate?.name}"?
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Uma cópia será criada com o nome "{areaToDuplicate?.name} (Cópia)".
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDuplicateDialog(false)}>
            Cancelar
          </Button>
          <Button 
            onClick={handleConfirmDuplicate}
            variant="contained"
          >
            Duplicar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog para confirmar exclusão */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <DeleteIcon color="error" />
          Confirmar Exclusão
        </DialogTitle>
        <DialogContent>
          <Typography>
            Tem certeza que deseja excluir a área "{selectedArea?.name}"?
            Esta ação não pode ser desfeita.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Maps;
