import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Box,
  Grid,
  InputAdornment,
  Chip
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

// Função para converter hectares em alqueires
const hectareToAlqueire = (hectares) => {
  const alqueires = hectares / 2.42;
  return alqueires.toFixed(2);
};

const Spraying = () => {
  const [operations, setOperations] = useState(() => {
    const savedOperations = localStorage.getItem('agroDroneOperations');
    return savedOperations ? JSON.parse(savedOperations).map(op => ({
      ...op,
      area: op.area || '0.00'
    })) : [];
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [mappedAreas, setMappedAreas] = useState(() => {
    const savedAreas = localStorage.getItem('mappedAreas');
    console.log('Carregando áreas iniciais:', savedAreas);
    return savedAreas ? JSON.parse(savedAreas) : [];
  });
  const [selectedMixture, setSelectedMixture] = useState('');
  const [mixtures, setMixtures] = useState(() => {
    const savedMixtures = localStorage.getItem('agroDroneMixtures');
    return savedMixtures ? JSON.parse(savedMixtures).map(mixture => ({
      ...mixture,
      products: mixture.products?.map(p => ({
        ...p,
        dosage: p.dosage || '0.00'
      })) || []
    })) : [];
  });
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isSelectingArea, setIsSelectingArea] = useState(false);
  const [totalVolume, setTotalVolume] = useState('0.00');

  // Estado inicial com valores default
  const initialFormState = {
    area: '0.00',
    selectedAreaId: '',
    selectedAreaName: '',
    notes: ''
  };

  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
    // Carregar operações
    const storedOperations = localStorage.getItem('agroDroneOperations');
    if (storedOperations) {
      setOperations(JSON.parse(storedOperations).map(op => ({
        ...op,
        area: op.area || '0.00'
      })));
    }
    
    // Carregar áreas mapeadas
    const storedAreas = localStorage.getItem('mappedAreas');
    console.log('Carregando áreas no useEffect:', storedAreas);
    if (storedAreas) {
      try {
        const areas = JSON.parse(storedAreas);
        console.log('Áreas parseadas:', areas);
        setMappedAreas(areas);
      } catch (error) {
        console.error('Erro ao parsear áreas:', error);
        setMappedAreas([]);
      }
    }

    const savedMixtures = localStorage.getItem('agroDroneMixtures');
    if (savedMixtures) {
      setMixtures(JSON.parse(savedMixtures).map(mixture => ({
        ...mixture,
        products: mixture.products?.map(p => ({
          ...p,
          dosage: p.dosage || '0.00'
        })) || []
      })));
    }
  }, []);

  useEffect(() => {
    console.log('Áreas mapeadas atualizadas:', mappedAreas);
  }, [mappedAreas]);

  const handleOpenDialog = (operation = null) => {
    if (operation) {
      setSelectedOperation(operation);
      setFormData({
        ...operation,
      });
    } else {
      setSelectedOperation(null);
      setFormData(initialFormState);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedOperation(null);
    setFormData(initialFormState);
    setSelectedMixture('');
  };

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value
    });
  };

  const handleSubmit = () => {
    if (!formData.area || !selectedMixture) return;

    const selectedMixtureDetails = mixtures.find(m => m.id === selectedMixture);
    const selectedArea = mappedAreas.find(a => a.id === formData.selectedAreaId);
    
    const newOperation = {
      id: selectedOperation ? selectedOperation.id : Date.now(),
      area: formData.area || '0.00',
      areaName: selectedArea ? selectedArea.name : 'N/A',
      areaId: formData.selectedAreaId || '',
      date: new Date().toISOString(),
      mixture: selectedMixtureDetails || null,
      status: 'Agendada',
      notes: formData.notes || ''
    };

    let updatedOperations;
    if (selectedOperation) {
      updatedOperations = operations.map(op =>
        op.id === selectedOperation.id ? newOperation : op
      );
    } else {
      updatedOperations = [...operations, newOperation];
    }

    setOperations(updatedOperations);
    localStorage.setItem('agroDroneOperations', JSON.stringify(updatedOperations));
    handleCloseDialog();
  };

  const handleDelete = (id) => {
    setOperations(operations.filter(operation => operation.id !== id));
    localStorage.setItem('agroDroneOperations', JSON.stringify(operations.filter(operation => operation.id !== id)));
  };

  const handleAreaChange = (value, type = 'hectares') => {
    let numValue = parseFloat(value) || 0;
    
    // Limitar a números menores que 1000
    if (numValue > 1000) {
      numValue = 999.99;
    }
    
    // Se o valor vier em alqueires, converter para hectares
    if (type === 'alqueires') {
      numValue = alqueiresToHectares(numValue);
    }
    
    setFormData(prev => ({
      ...prev,
      area: formatNumber(numValue)
    }));
  };

  const handleAreaSelect = (e) => {
    const selectedArea = mappedAreas.find(a => a.id === e.target.value);
    console.log('Área selecionada:', selectedArea);
    if (selectedArea) {
      const newArea = selectedArea.area;
      setFormData(prev => ({
        ...prev,
        selectedAreaId: selectedArea.id,
        area: newArea,
        selectedAreaName: selectedArea.name
      }));

      // Recalcular volume total se já tiver uma mistura selecionada
      if (selectedMixture) {
        const newVolume = calculateTotalVolume(newArea, selectedMixture);
        setTotalVolume(newVolume);
        console.log('Volume calculado após selecionar área:', newVolume);
      }
    }
  };

  const handleMixtureSelect = (e) => {
    const mixtureId = e.target.value;
    setSelectedMixture(mixtureId);
    
    if (mixtureId && formData.area) {
      const newVolume = calculateTotalVolume(formData.area, mixtureId);
      setTotalVolume(newVolume);
      console.log('Volume calculado após selecionar mistura:', newVolume);
    }
  };

  const handleDeleteClick = () => {
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedOperation) {
      const updatedOperations = operations.filter(op => op.id !== selectedOperation.id);
      setOperations(updatedOperations);
      localStorage.setItem('agroDroneOperations', JSON.stringify(updatedOperations));
      setConfirmDeleteOpen(false);
      handleCloseDialog();
    }
  };

  const handleCancelDelete = () => {
    setConfirmDeleteOpen(false);
  };

  const handleEditOperation = (operation) => {
    if (!operation) return;
    
    setSelectedOperation(operation);
    setFormData({
      area: formatNumber(operation.area),
      selectedAreaId: operation.areaId || '',
      selectedAreaName: operation.areaName || '',
      notes: operation.notes || ''
    });
    setSelectedMixture(operation.mixture?.id || '');
    handleOpenDialog();
  };

  // 1 alqueire = 2.42 hectares
  const hectaresToAlqueires = (hectares) => {
    const value = parseFloat(hectares) || 0;
    return value / 2.42;
  };

  const alqueiresToHectares = (alqueires) => {
    const value = parseFloat(alqueires) || 0;
    return value * 2.42;
  };

  // Função para formatar a data de forma compacta
  const formatCompactDate = (dateStr) => {
    if (!dateStr) return 'N/A';
    const date = new Date(dateStr);
    return date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    });
  };

  // Função segura para formatar números
  const formatNumber = (value) => {
    if (!value || value === null || value === undefined || value === '') {
      return '0.00';
    }
    const number = parseFloat(value);
    return isNaN(number) ? '0.00' : number.toFixed(2);
  };

  const calculateTotalVolume = (area, mixtureId) => {
    if (!area || !mixtureId) return '0.00';
    const mixture = mixtures.find(m => m.id === mixtureId);
    if (!mixture || !mixture.applicationRate) return '0.00';
    
    const areaValue = parseFloat(area) || 0;
    const taxa = parseFloat(mixture.applicationRate) || 0;
    const volume = areaValue * taxa;
    return volume.toFixed(2);
  };

  // Memoize a lista de operações formatada
  const formattedOperations = useMemo(() => {
    if (!operations || !Array.isArray(operations)) return [];
    
    return operations.map(operation => {
      if (!operation) return null;
      
      return {
        ...operation,
        area: formatNumber(operation.area),
        areaName: operation.areaName || 'N/A',
        mixture: operation.mixture ? {
          ...operation.mixture,
          name: operation.mixture.name || 'N/A',
          products: Array.isArray(operation.mixture.products) 
            ? operation.mixture.products.map(p => ({
                ...p,
                name: p?.name || 'Sem nome',
                dosage: formatNumber(p?.dosage)
              }))
            : []
        } : null
      };
    }).filter(Boolean);
  }, [operations]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 2,
          px: 1 // Adiciona padding horizontal igual ao da tabela
        }}>
          <Typography component="h2" variant="h6" color="primary" gutterBottom={false}>
            Pulverização
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog()}
          >
            Nova Operação
          </Button>
        </Box>

        <TableContainer>
          <Table size="small" sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Área</TableCell>
                <TableCell>Calda</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formattedOperations.map((operation) => (
                <TableRow key={operation.id} hover>
                  <TableCell>
                    {formatCompactDate(operation.date)}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {operation.areaName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {operation.area} ha
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {operation.mixture?.name}
                    </Typography>
                    {operation.mixture?.products?.length > 0 && (
                      <Typography variant="caption" color="text.secondary" display="block">
                        {operation.mixture.products.map(p => 
                          `${p.name} (${formatNumber(p.dosage)}L/ha)`
                        ).join(', ')}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={operation.status || 'Pendente'}
                      color={
                        operation.status === 'Concluída' ? 'success' :
                        operation.status === 'Em Andamento' ? 'warning' : 'default'
                      }
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      onClick={() => handleDelete(operation.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Diálogo Principal de Operação */}
      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {selectedOperation ? 'Editar Operação' : 'Nova Operação'}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {!isSelectingArea && formData.selectedAreaId ? (
              <Box sx={{ mb: 2 }}>
                <Typography variant="body1" gutterBottom>
                  Área Selecionada: {formData.selectedAreaName} ({formData.area} ha / {hectareToAlqueire(formData.area)} alq)
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setIsSelectingArea(true)}
                  sx={{ mt: 1 }}
                >
                  Alterar Área
                </Button>
              </Box>
            ) : (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Área Mapeada</InputLabel>
                <Select
                  value={formData.selectedAreaId || ''}
                  onChange={handleAreaSelect}
                  label="Área Mapeada"
                  open={isSelectingArea}
                  onOpen={() => setIsSelectingArea(true)}
                  onClose={() => setIsSelectingArea(false)}
                >
                  {mappedAreas.map((area) => (
                    <MenuItem key={area.id} value={area.id}>
                      {area.name} ({area.area} ha / {hectareToAlqueire(area.area)} alq)
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Área (ha)"
                  type="number"
                  value={formData.area || '0.00'}
                  onChange={(e) => handleInputChange('area', e.target.value)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">ha</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Área (alq)"
                  type="number"
                  value={hectareToAlqueire(formData.area)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">alq</InputAdornment>,
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Calda</InputLabel>
              <Select
                value={selectedMixture || ''}
                onChange={handleMixtureSelect}
                label="Calda"
              >
                {mixtures.map((mixture) => (
                  <MenuItem key={mixture.id} value={mixture.id}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="body2">
                        {mixture.name || 'Sem nome'}
                      </Typography>
                      {mixture.products && mixture.products.length > 0 && (
                        <Typography variant="caption" color="text.secondary">
                          {mixture.products.map(p => 
                            `${p.name} (${formatNumber(p.dosage)}L/ha)`
                          ).join(', ')}
                        </Typography>
                      )}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedMixture && (
              <>
                <TableContainer component={Paper} variant="outlined" sx={{ mb: 2 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Produto</TableCell>
                        <TableCell align="right">Dosagem (L/ha)</TableCell>
                        <TableCell align="right">Total (L)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(() => {
                        const mixture = mixtures.find(m => m.id === selectedMixture);
                        if (!mixture || !mixture.products || !Array.isArray(mixture.products)) {
                          return (
                            <TableRow>
                              <TableCell colSpan={3} align="center">
                                Nenhum produto cadastrado
                              </TableCell>
                            </TableRow>
                          );
                        }

                        return mixture.products.map((product, index) => {
                          const totalAmount = parseFloat(product.dosage || 0) * parseFloat(formData.area || 0);
                          return (
                            <TableRow key={index}>
                              <TableCell>{product.name || 'N/A'}</TableCell>
                              <TableCell align="right">{formatNumber(product.dosage)}</TableCell>
                              <TableCell align="right">{formatNumber(totalAmount)}</TableCell>
                            </TableRow>
                          );
                        });
                      })()}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 2 }}>
                  Volume total de calda: {totalVolume} L
                </Typography>
              </>
            )}

            <TextField
              fullWidth
              multiline
              rows={3}
              label="Observações"
              value={formData.notes || ''}
              onChange={(e) => handleInputChange('notes', e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button 
            onClick={handleSubmit}
            variant="contained"
            disabled={!formData.selectedAreaId || !selectedMixture}
          >
            {selectedOperation ? 'Atualizar' : 'Criar'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Spraying;
