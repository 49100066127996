import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Tooltip,
  Alert,
} from '@mui/material';

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  PhotoCamera as PhotoCameraIcon,
  Warning as WarningIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import ProductTypes from './ProductTypes';

// Produtos de exemplo para teste
const defaultProducts = [
  {
    id: '1',
    name: 'Roundup',
    manufacturer: 'Monsanto',
    type: 'Herbicida',
    description: 'Herbicida sistêmico não seletivo',
    dosage: '2-4 L/ha',
    safetyInterval: 30,
    restrictions: ['Não aplicar em dias chuvosos', 'Manter distância de áreas residenciais'],
    image: 'https://images.unsplash.com/photo-1592978122244-d8facb5d2b51?w=400&auto=format&fit=crop&q=80',
    price: 150.00,
    stock: 100,
  },
  {
    id: '2',
    name: 'Engeo Pleno',
    manufacturer: 'Syngenta',
    type: 'Inseticida',
    description: 'Inseticida sistêmico de amplo espectro',
    dosage: '250-300 ml/ha',
    safetyInterval: 21,
    restrictions: ['Evitar aplicação em horários quentes', 'Usar EPI adequado'],
    image: 'https://images.unsplash.com/photo-1599940824399-b87987ceb72a?w=400&auto=format&fit=crop&q=80',
    price: 280.00,
    stock: 50,
  }
];

const Products = () => {
  const [products, setProducts] = useState(() => {
    const savedProducts = localStorage.getItem('agroDroneProducts');
    return savedProducts ? JSON.parse(savedProducts) : defaultProducts;
  });

  const [productTypes, setProductTypes] = useState(() => {
    const savedTypes = localStorage.getItem('agroDroneProductTypes');
    return savedTypes ? JSON.parse(savedTypes) : [
      'Herbicida',
      'Fungicida',
      'Inseticida',
      'Adjuvante',
      'Óleo',
      'Fertilizante'
    ];
  });

  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [newProduct, setNewProduct] = useState({
    name: '',
    manufacturer: '',
    type: '',
    description: '',
    dosage: '',
    safetyInterval: '',
    restrictions: [],
    image: '',
    price: '',
    stock: '',
  });
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem('agroDroneProducts', JSON.stringify(products));
  }, [products]);

  useEffect(() => {
    localStorage.setItem('agroDroneProductTypes', JSON.stringify(productTypes));
  }, [productTypes]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setNewProduct({
      name: product.name,
      manufacturer: product.manufacturer,
      type: product.type,
      description: product.description,
      dosage: product.dosage,
      safetyInterval: product.safetyInterval,
      restrictions: product.restrictions,
      image: product.image,
      price: product.price,
      stock: product.stock,
    });
    setOpen(true);
  };

  const handleSave = () => {
    if (!newProduct.name.trim()) return;

    const product = {
      ...newProduct,
      id: selectedProduct ? selectedProduct.id : Date.now().toString(),
    };

    if (selectedProduct) {
      setProducts(products.map(p => p.id === selectedProduct.id ? product : p));
    } else {
      setProducts([...products, product]);
    }

    handleClose();
  };

  const handleDeleteConfirm = () => {
    setProducts(products.filter(product => product.id !== selectedProduct.id));
    setConfirmDeleteOpen(false);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProduct(null);
    setNewProduct({
      name: '',
      manufacturer: '',
      type: '',
      description: '',
      dosage: '',
      safetyInterval: '',
      restrictions: '',
      image: '',
      price: '',
      stock: '',
    });
  };

  const handleTypeChange = (e) => {
    const value = e.target.value;
    if (value === '__new') {
      const newType = prompt('Digite o novo tipo de produto:');
      if (newType && newType.trim() && !productTypes.includes(newType.trim())) {
        const updatedTypes = [...productTypes, newType.trim()];
        setProductTypes(updatedTypes);
        setNewProduct({ ...newProduct, type: newType.trim() });
      }
    } else {
      setNewProduct({ ...newProduct, type: value });
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Produtos
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
          >
            Adicionar Produto
          </Button>
        </Box>

        <Grid container spacing={2}>
          {products.map((product) => (
            <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={product.image}
                  alt={product.name}
                />
                <CardContent>
                  <Typography variant="h5" component="div">
                    {product.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {product.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" startIcon={<EditIcon />} onClick={() => handleEdit(product)}>
                    Editar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {selectedProduct ? 'Editar Produto' : 'Adicionar Novo Produto'}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Nome do Produto"
              fullWidth
              variant="outlined"
              value={newProduct.name}
              onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Fabricante"
              fullWidth
              variant="outlined"
              value={newProduct.manufacturer}
              onChange={(e) => setNewProduct({ ...newProduct, manufacturer: e.target.value })}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>Tipo</InputLabel>
              <Select
                value={newProduct.type}
                onChange={handleTypeChange}
                label="Tipo"
              >
                {productTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
                <MenuItem value="__new">
                  <Typography color="primary">+ Adicionar Novo Tipo</Typography>
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              label="Descrição"
              fullWidth
              variant="outlined"
              value={newProduct.description}
              onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Dosagem"
              fullWidth
              variant="outlined"
              value={newProduct.dosage}
              onChange={(e) => setNewProduct({ ...newProduct, dosage: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Intervalo de Segurança"
              fullWidth
              variant="outlined"
              value={newProduct.safetyInterval}
              onChange={(e) => setNewProduct({ ...newProduct, safetyInterval: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Restrições"
              fullWidth
              variant="outlined"
              value={newProduct.restrictions}
              onChange={(e) => setNewProduct({ ...newProduct, restrictions: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Imagem"
              fullWidth
              variant="outlined"
              value={newProduct.image}
              onChange={(e) => setNewProduct({ ...newProduct, image: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Preço"
              fullWidth
              variant="outlined"
              value={newProduct.price}
              onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Estoque"
              fullWidth
              variant="outlined"
              value={newProduct.stock}
              onChange={(e) => setNewProduct({ ...newProduct, stock: e.target.value })}
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', px: 3, pb: 2 }}>
            <Box>
              {selectedProduct && (
                <Button
                  onClick={() => setConfirmDeleteOpen(true)}
                  color="error"
                  startIcon={<DeleteIcon />}
                >
                  Excluir
                </Button>
              )}
            </Box>
            <Box>
              <Button onClick={handleClose}>Cancelar</Button>
              <Button onClick={handleSave} variant="contained">
                {selectedProduct ? 'Salvar' : 'Adicionar'}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirmDeleteOpen}
          onClose={() => setConfirmDeleteOpen(false)}
        >
          <DialogTitle>Confirmar Exclusão</DialogTitle>
          <DialogContent>
            <Typography>
              Tem certeza que deseja excluir o produto "{selectedProduct?.name}"?
              Esta ação não pode ser desfeita.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDeleteOpen(false)}>Cancelar</Button>
            <Button onClick={handleDeleteConfirm} color="error" variant="contained">
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
  );
};

export default Products;
