import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  ButtonGroup,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  Divider,
  Chip,
  useTheme,
  useMediaQuery,
  TextField,
  InputAdornment,
} from '@mui/material';
import {
  Search as SearchIcon,
  FilterList as FilterIcon,
  Download as DownloadIcon,
  NavigateNext as NavigateNextIcon,
  CalendarToday as CalendarIcon,
  LocalOffer as TagIcon,
  Place as PlaceIcon,
} from '@mui/icons-material';

const Reports = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  // Dados mockados para exemplo
  const reports = [
    {
      id: 1,
      title: 'Pulverização Fazenda São João',
      date: '2024-01-05',
      area: '150 ha',
      type: 'pulverizacao',
      status: 'Concluído',
      tags: ['Soja', 'Herbicida'],
    },
    {
      id: 2,
      title: 'Mapeamento Sítio Boa Vista',
      date: '2024-01-04',
      area: '75 ha',
      type: 'mapeamento',
      status: 'Em andamento',
      tags: ['Milho', 'Topografia'],
    },
    {
      id: 3,
      title: 'Análise de Solo Santa Clara',
      date: '2024-01-03',
      area: '200 ha',
      type: 'analise',
      status: 'Pendente',
      tags: ['Análise', 'Fertilidade'],
    },
    {
      id: 4,
      title: 'Pulverização Área Norte',
      date: '2024-01-02',
      area: '90 ha',
      type: 'pulverizacao',
      status: 'Concluído',
      tags: ['Algodão', 'Inseticida'],
    },
    {
      id: 5,
      title: 'Mapeamento Área Sul',
      date: '2024-01-01',
      area: '120 ha',
      type: 'mapeamento',
      status: 'Concluído',
      tags: ['Café', 'Irrigação'],
    },
  ];

  const filters = [
    { key: 'all', label: 'Todos' },
    { key: 'pulverizacao', label: 'Pulverização' },
    { key: 'mapeamento', label: 'Mapeamento' },
    { key: 'analise', label: 'Análise' },
  ];

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'concluído':
        return theme.palette.success.main;
      case 'em andamento':
        return theme.palette.warning.main;
      case 'pendente':
        return theme.palette.info.main;
      default:
        return theme.palette.grey[500];
    }
  };

  const filteredReports = reports
    .filter(report => {
      if (selectedFilter === 'all') return true;
      return report.type === selectedFilter;
    })
    .filter(report =>
      report.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      report.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
    );

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Header */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Relatórios
        </Typography>
        <Typography color="text.secondary">
          Visualize e gerencie seus relatórios de operações
        </Typography>
      </Box>

      {/* Search and Filter Section */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: isMobile ? 'column' : 'row',
          gap: 2,
          alignItems: isMobile ? 'stretch' : 'center',
          justifyContent: 'space-between',
          mb: 2
        }}>
          <TextField
            placeholder="Buscar relatórios..."
            variant="outlined"
            size="small"
            fullWidth={isMobile}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <ButtonGroup 
            variant="outlined" 
            size="small"
            sx={{ 
              flexWrap: 'wrap',
              '& > *': { flexGrow: isMobile ? 1 : 0 }
            }}
          >
            {filters.map((filter) => (
              <Button
                key={filter.key}
                onClick={() => setSelectedFilter(filter.key)}
                variant={selectedFilter === filter.key ? 'contained' : 'outlined'}
              >
                {filter.label}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
      </Paper>

      {/* Reports List */}
      <Paper>
        <List>
          {filteredReports.map((report, index) => (
            <React.Fragment key={report.id}>
              {index > 0 && <Divider />}
              <ListItem
                sx={{
                  py: 2,
                  transition: '0.3s',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                      <Typography variant="h6">{report.title}</Typography>
                      <Chip
                        label={report.status}
                        size="small"
                        sx={{
                          bgcolor: getStatusColor(report.status),
                          color: 'white',
                        }}
                      />
                    </Box>
                  }
                  secondary={
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <CalendarIcon fontSize="small" color="action" />
                          <Typography variant="body2">{report.date}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <PlaceIcon fontSize="small" color="action" />
                          <Typography variant="body2">{report.area}</Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                        {report.tags.map((tag, tagIndex) => (
                          <Chip
                            key={tagIndex}
                            label={tag}
                            size="small"
                            icon={<TagIcon />}
                            variant="outlined"
                          />
                        ))}
                      </Box>
                    </Box>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="download">
                    <DownloadIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </Container>
  );
};

export default Reports;
